export default {
    install(app) {
        try {
            if (
                typeof window !== 'undefined' &&
                import.meta.env.VITE_APP_ENV === 'production'
            ) {
                import('vue-matomo')
                    .then(VueMatomo => {
                        app.use(VueMatomo.default, {
                            host: 'https://a.kinchaku.com',
                            siteId: 5,
                            router: null,
                            enableLinkTracking: true,
                            requireConsent: true,
                            trackInitialView: true,
                            disableCookies: false,
                            enableHeartBeatTimer: true,
                            heartBeatTimerInterval: 15,
                            debug: false,
                            userId: undefined,
                            cookieDomain: '*.kinchaku.me',
                            domains: '*.kinchaku.me',
                            preInitActions: [],
                        })
                    })
                    .catch(e => {
                        /* eslint-disable no-console */
                        console.warn('Matomo initialization error', e)
                    })
            }
        } catch (e) {
            /* eslint-disable no-console */
            console.warn('Matomo initialization error', e)
        }
    },
}
