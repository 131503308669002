import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'

export const baseURL = import.meta.env.VITE_BACKEND_URI || ''

const api = axios.create({ baseURL: '/storefront/api' })

api.interceptors.response.use(
    function ({ data }) {
        return transformResponseData(data)
    },
    function (error) {
        if (!error.response) {
            return Promise.reject(error.message)
        }
        switch (error.response.status) {
            case 401:
                // clearAccessToken()
                return Promise.reject(error)
            case 503:
                return Promise.reject(error)
            case 500:
                if (
                    error.response.data?.errors?.message ==
                    'Token Signature could not be verified.'
                ) {
                    // clearAccessToken()
                    return Promise.reject(error)
                }
        }
        return Promise.reject(error)
    }
)

function transformResponseData(data) {
    if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data)) {
            return data.map(transformResponseData)
        } else {
            const transformedObject = {}
            for (const key in data) {
                // eslint-disable-next-line no-prototype-builtins
                if (data.hasOwnProperty(key)) {
                    const value = data[key]
                    if (typeof value === 'object' && value !== null) {
                        transformedObject[key] = transformResponseData(value)
                    } else if (value === 'true') {
                        transformedObject[key] = true
                    } else if (value === 'false') {
                        transformedObject[key] = false
                    } else {
                        transformedObject[key] = value
                    }
                }
            }
            return transformedObject
        }
    }

    return data
}

export default api
