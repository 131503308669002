import '../css/main.scss'
import '../css/app.css'

import 'vue'
import axios from 'axios'
declare global {
    interface Window {
        Pusher: any
        _mtm: any[]
        _paq: any
        axios: typeof axios
        Square: any | null
        AppleID: any | null
    }
}

// window.axios = axios
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

import * as Sentry from '@sentry/vue'
import capitalize from 'lodash/capitalize'
import vue3GoogleLogin from 'vue3-google-login'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import { createApp, h, DefineComponent, App as VueApp } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import type { Plugin } from 'vue'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

import store from './store.js'
import i18n from './utils/vue-i18n'
import formatCurrency from './utils/format-currency'
import formatDate from './utils/date'
import analytics from './plugins/analytics'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import notifications from './plugins/notifications'
import { toast } from './plugins/notifications'
import * as storage from './utils/storage'
import ja from './ja'
// import api from './api'
import { useAuth } from '@/composables/use-auth'

declare module 'vue' {
    interface ComponentCustomProperties {
        $t(key: string, replacements?: object): string
        $cu(amount: number, currency?: string): string
        $d(date: Date | string, format: string, locale?: string): string
        $c(value: string): string
        $toast(type: string, value: string): void
    }
}

const appName = import.meta.env.VITE_APP_NAME || 'Kinchaku'

createInertiaApp({
    // title: (title: string) => (title ? `${title} - ${appName}` : `${appName}`),
    title: (title: string) => (title ? title : appName),
    resolve: name =>
        resolvePageComponent(
            `./pages/${name}.vue`,
            import.meta.glob('./pages/**/*.vue')
        ) as Promise<DefineComponent>,
    setup({
        el,
        App,
        props,
        plugin,
    }: {
        el: Element
        App: DefineComponent
        props: Record<string, any>
        plugin: Plugin
    }): VueApp {
        const app = createApp({
            data() {
                return {
                    lang: 'en',
                    scriptsLoaded: false,
                    isMobile: false,
                    isSSR: false,
                    channel: null,
                    echo: null,
                    retryTimer: 0,
                }
            },

            computed: {
                $echo() {
                    return this.echo || {}
                },

                dev() {
                    return import.meta.env.DEV
                },

                base_url() {
                    return import.meta.env.VITE_BASE_URL
                },
                backend_url() {
                    return import.meta.env.VITE_BACKEND_URI
                },

                query() {
                    return window?.location?.search
                        ? new URLSearchParams(window?.location?.search)
                        : null
                },

                /**
                 * Return true if customer's language is english.
                 * @return {boolean}
                 */
                is_english() {
                    return this.lang === 'en'
                },

                is_japanese() {
                    return this.lang === 'ja'
                },

                locale() {
                    switch (this.lang) {
                        case 'ja':
                            return 'ja-jp'
                        default:
                            return 'en-us'
                    }
                },

                is_dark_mode() {
                    return (
                        window.matchMedia &&
                        window.matchMedia('(prefers-color-scheme: dark)').matches
                    )
                },

                currency() {
                    return store.getters.currency || 'jpy'
                },
                workspace() {
                    return store.getters.workspace
                },
                date_format() {
                    return 'yyyy/MM/dd'
                },
                date_format_with_time() {
                    return 'yyyy/MM/dd HH:mm'
                },
                user() {
                    return useAuth().value as unknown as App.Data.Shared.UserData | null
                },
            },

            watch: {
                lang(value) {
                    this.changeLanguage(value)
                },
                'user.language'() {
                    if (!['ja', 'en'].includes(this.user?.language)) return

                    const userLang = this.user?.language

                    if (userLang && userLang !== this.lang) this.setLanguage(userLang)
                },
                user(value) {
                    if (!this.echo) return
                    if (value) {
                        this.channel = this.$echo.join('store')
                    } else {
                        this.$echo.leave(this.channel)
                    }
                },
            },

            created() {
                i18n.init({ ja })

                if (this.query && this.query.get('kc')) {
                    store.dispatch('setCampaign', this.query.get('kc'))
                }
            },

            mounted() {
                if (!this.echo) {
                    window.Pusher = Pusher

                    interface EchoOptions {
                        authEndpoint: string
                        // auth: { headers: { 'X-Auth-Token': string } }
                        key: string
                        disableStats: boolean
                        enabledTransports: string[]
                        broadcaster: any
                        wsHost?: string
                        cluster?: string
                    }

                    // Then in the code:
                    const options: EchoOptions = {
                        authEndpoint: import.meta.env.VITE_BACKEND_URI + '/storefront/ws/auth',
                        // auth: { headers: { 'X-Auth-Token': getAccessToken() } },
                        key: import.meta.env.VITE_REVERB_APP_KEY,
                        disableStats: true,
                        enabledTransports: ['ws', 'wss'],
                        broadcaster: import.meta.env.VITE_BROADCASTER,
                    }
                    if (options.broadcaster === 'reverb') {
                        options.wsHost = import.meta.env.VITE_PUSHER_HOST
                    } else {
                        options.cluster = 'ap3'
                    }

                    this.echo = new Echo(options)
                    if (this.user) {
                        this.channel = this.$echo.join('store')
                    }
                }

                const defaultLang = /^ja\b/.test(navigator.language) ? 'ja' : 'en'
                this.setLanguage(storage.local.getItem('app.language') || defaultLang)
                storage.local.setItem('app.language', this.lang)
                if (!this.dev) {
                    const _mtm = (window._mtm = window._mtm || [])
                    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
                    const d = document,
                        g = d.createElement('script'),
                        s = d.getElementsByTagName('script')[0]
                    g.type = 'text/javascript'
                    g.async = true
                    g.src = 'https://a.kinchaku.com/js/container_BVrhshZU.js'
                    s.parentNode.insertBefore(g, s)
                }

                window.addEventListener('resize', this.checkIsMobile)
                this.checkIsMobile()

                if (this.scriptsLoaded) return

                if (!this.workspace) return

                if (this.workspace.connect_id) {
                    const script = 'https://js.stripe.com/v3/'
                    Sentry.setTag('payment', 'Stripe')
                    this.loadScript(script)
                } else if (this.workspace.merchant_id) {
                    Sentry.setTag('payment', 'Squareup' + (this.dev ? ' sandbox' : ''))
                    const script = this.dev
                        ? 'https://sandbox.web.squarecdn.com/v1/square.js'
                        : 'https://web.squarecdn.com/v1/square.js'
                    this.loadScript(script)
                } else {
                    Sentry.setTag('payment', 'None')
                }
                this.scriptsLoaded = true

                if (this.query && this.query.get('session')) {
                    store.dispatch('jwtLogin', this.query.get('session'))
                }
            },
            unmounted() {
                window.removeEventListener('resize', this.checkIsMobile)
            },

            methods: {
                startRetryTimer(value: number) {
                    this.retryTimer = value

                    const interval = setInterval(() => {
                        this.retryTimer--

                        if (this.retryTimer === 0) clearInterval(interval)
                    }, 1000)
                },

                handleError(error: any) {
                    // eslint-disable-next-line
                    console.error(error)
                    if (!error) return
                    const status = error?.response?.status
                    const form = error?.form
                    const lang = this.user?.language || 'en'

                    if (
                        status == 422 &&
                        form &&
                        Object.keys(error.response?.data?.errors || {}).length > 0 &&
                        typeof Object.values(error.response?.data?.errors)[0] === 'object'
                    ) {
                        for (const errorField in error.response?.data?.errors) {
                            if (form.fields.includes(errorField)) {
                                form.setFieldsErrors({
                                    [errorField]: error.response?.data?.errors[errorField],
                                })
                            } else {
                                toast.error(
                                    Array.isArray(error.response?.data?.errors[errorField])
                                        ? error.response?.data?.errors[errorField]
                                              .map(e => i18n.translate(lang, e))
                                              .join(', ')
                                        : i18n.translate(
                                              lang,
                                              error.response?.data?.errors[errorField]
                                          )
                                )
                            }
                        }

                        return
                    }

                    if (typeof error !== 'string') {
                        if (error == 401) {
                            error = 'Access Denied'
                            toast.error(i18n.translate(lang, error))

                            return store.dispatch('goToSignIn')
                        } else if (error.errors && error.errors.length) {
                            error = error.errors[0].message || error.errors[0]
                        } else if (!error.response && error.error) {
                            error = error.error.message
                        } else if (!error.response) {
                            error = error.message || 'Request failed'
                        } else if (error.response && (status == 401 || status == 403)) {
                            error = 'Access Denied'
                        } else if (status == 429) {
                            return this.startRetryTimer(60)
                        } else if (
                            error.response &&
                            error.response.data.errors &&
                            error.response.data.errors.message
                        ) {
                            error = error.response.data.errors.message
                        } else if (
                            // stripe-style errors
                            error.response &&
                            error.response.data.error &&
                            error.response.data.error.message
                        ) {
                            error = error.response.data.error.message
                        } else if (error.response && error.response.data.errors) {
                            let errorString = ''
                            for (const prop in error.response.data.errors) {
                                errorString += error.response.data.errors[prop] + '\n'
                            }
                            error = errorString
                        } else if (error.response && error.response.data.message) {
                            error = error.response.data.message
                        } else if (error?.response?.data?.errorMessage) {
                            error = error.response.data.errorMessage
                        } else {
                            error = error.message || 'Request failed'
                        }
                    }

                    toast.error(i18n.translate(lang, error))
                },

                setLanguage(lang: string | undefined) {
                    if (lang === undefined) return
                    this.lang = lang
                    storage.local.setItem('app.language', lang)
                },

                async changeLanguage(language: string) {
                    this.setLanguage(language)
                    if (this.user && language != this.user.language) {
                        try {
                            await store.dispatch('updateLanguage', language)
                        } catch (e) {
                            this.handleError(e)
                        }
                        this.user.language = language
                    }
                },

                loadScript(script) {
                    const plugin = document.createElement('script')
                    plugin.setAttribute('src', script)
                    plugin.async = true
                    document.head.appendChild(plugin)
                },

                // used in resize event
                checkIsMobile() {
                    this.isMobile = window.innerWidth < 768
                },

                getKeyByLang(object, key) {
                    if (this.lang === 'en') {
                        return object?.[`${key}_en`] || object?.[key] || ''
                    }

                    return object?.[key] || object?.[`${key}_en`] || ''
                },
            },

            render: () => h(App, props),
        })
            .use(plugin)
            .use(store)
            .use(analytics)
            .use(Toast)
            .use(notifications)

        const dsn = import.meta.env.VITE_SENTRY_DSN
        const isLocal = import.meta.env.VITE_APP_ENV === 'local'
        if (dsn) {
            Sentry.init({
                app,
                dsn,
                environment: import.meta.env.MODE,
                release: import.meta.env.VITE_GIT_TAG || 'unknown',
                tracesSampleRate: 0.1,
                tracePropagationTargets: [/^\/storefront\/api\//],
                replaysSessionSampleRate: 0,
                replaysOnErrorSampleRate: 1.0,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration({
                        maskAllText:
                            import.meta.env.MODE === 'production' ||
                            import.meta.env.MODE === 'prod',
                    }),
                ],
                logErrors: isLocal,
            })
        }

        if (isLocal) {
            app.config.errorHandler = function (error, vm, info) {
                // eslint-disable-next-line
                console.error(error, vm, info)
            }
        }

        if (import.meta.env.VITE_GOOGLE_CLIENT_ID) {
            app.use(vue3GoogleLogin, {
                clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
                error: error => {
                    // eslint-disable-next-line
                    console.error(error)
                },
            })
        }

        app.mixin({
            methods: {
                $t(key: string, replacements = {}) {
                    return i18n.translate(this.$root.lang, key, replacements)
                },
                $cu(amount: number, currency?: string) {
                    return formatCurrency(amount, currency)
                },
                $d(date: string | Date, format: string, locale: string = this.$root.lang) {
                    return formatDate(date, format, locale)
                },
                $c(value: string) {
                    return capitalize(value)
                },
                $toast(type: string, value: string) {
                    switch (type) {
                        case 'success':
                            toast.success(value)
                            break
                        case 'error':
                            toast.error(value)
                            break
                        case 'warning':
                            toast.warning(value)
                            break
                        default:
                            toast(value)
                    }
                },
            },
        })

        app.mount(el)

        return app
    },
    progress: {
        color: '#4B5563',
    },
})
