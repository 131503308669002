import { usePage, router } from '@inertiajs/vue3'
import { TYPE as NotificationType, useToast } from 'vue-toastification'

const toast = useToast()

export default {
    install() {
        router.on('finish', () => {
            const props = usePage().props

            if (Object.keys(props.errors).length) {
                for (const v of Object.values(props.errors)) {
                    toast.error(v)
                }
            }

            const notification: App.Data.Shared.NotificationData = props.notification
            if (props.notification) {
                toast(notification.body, { type: notification.type as NotificationType })
                router.reload({ reset: ['notification'] })
            }
        })
    },
}

export { toast }
